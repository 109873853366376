import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import AdminNav from "../navbars/adminnav";

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    @media (min-width: 1024px) {
        display: grid;
        flex-direction: inherit;
        grid-template-columns: min-content 1fr;    
        grid-template-rows: inherit;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 50px 0 0 0;
    @media (min-width: 1024px) {
        padding: 0;
    }
`

const WebLayout = () => {

    const [ openNav, setOpenNav ] = React.useState(false);

    const onSwitchNav = () => {
        setOpenNav(!openNav);
    }

    const closeNav = () => {
        setOpenNav(false);
    }

    return (
        <Root>
            <AdminNav openNav={openNav} onSwitchNav={onSwitchNav} closeNav={closeNav} />
            <Content>
                <Outlet />
            </Content>
        </Root>
    );
}

export default WebLayout;