import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import InputImage from "../../inputs/inputImage";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SelectInput from "../../inputs/SelectInput";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
  }
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 1/4;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const Timeline = ({ data, setData }) => {
    const intl = useIntl();

    const selectOpt = [{
        label: intl.formatMessage({ id: "image from file" }),
        value: "image from file"
    },
    {
        label: intl.formatMessage({ id: "video from URL" }),
        value: "video from URL"
    }
    ]

    const videoOpt = [{
        label: intl.formatMessage({ id: "youtube" }),
        value: "youtube"
    },
    {
        label: intl.formatMessage({ id: "vimeo" }),
        value: "vimeo"
    }
    ]

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const youtubeParser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    };

    const vimeoParser = (url) => {
        var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
        var match = url.match(regExp);
        return (match && match[5].length >= 5) ? match[5] : false;
    }

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name == "srcVideo" && data.videoType.value == "youtube") {
            setData({
                ...data,
                [name]: value,
                idVideo: youtubeParser(value),
                img: null,
                alt: "",
            });
        }
        else if (name == "srcVideo" && data.videoType.value == "vimeo") {
            setData({
                ...data,
                [name]: value,
                idVideo: vimeoParser(value),
                img: null,
                alt: "",
            });
        }
        else {
            setData({
                ...data,
                [name]: value,
            });
        }

    };

    const onChangeImage = (files) => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("image", files[0]);
            axios
                .post(`${API_BACKEND}/sections/images`, formData, {})
                .then((res) => {
                    setData({
                        ...data,
                        img: res.data.url,
                        videoType: null,
                        srcVideo: "",
                        idVideo: ""
                    });
                })
                .catch((error) => {
                    alertMessage(error.response?.data?.msg, alertConstants.ERROR);
                });
        }
    };

    const handleChangeCheck = (e) => {
        const { name, checked } = e.target;
        setData({
            ...data,
            [name]: checked,
        });
    };

    const handleChangeAutocomplete = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });

    };

    const onResetImage = () => {
        setData({
            ...data,
            img: null,
        });
    };


    return (
        data && (
            <Root>
                <StyledTextfield
                    id="title"
                    name="title"
                    onChange={onChange}
                    value={data?.title}
                    label={intl.formatMessage({ id: "title" })}
                    variant="outlined"
                    width="100%"
                    margin="normal"
                    size="small"
                />
                <ContainerImgWithAlt>
                    <InputData width="70%">
                        <SelectInput
                            data={selectOpt}
                            value={data?.srcSelect?.value}
                            fieldlabel="value"
                            fieldvalue="value"
                            label={intl.formatMessage({ id: "select src" })}
                            name="srcSelect"
                            onChange={handleChangeAutocomplete}
                            width="100%"
                            margin="normal"
                            size="small"
                            required
                        />
                    </InputData>
                    {
                        (data?.srcSelect && data?.srcSelect?.value == "image from file") &&
                        <ContainerImgWithAlt>
                            <ImageContainer>
                                <InputImage
                                    id="timelineimage"
                                    file={data.img}
                                    onChange={onChangeImage}
                                    onReset={onResetImage}
                                />
                            </ImageContainer>
                            <StyledTextfield
                                id="alt"
                                name="alt"
                                onChange={onChange}
                                value={data?.alt}
                                label={intl.formatMessage({ id: "alternative text" })}
                                variant="outlined"
                                width="70%"
                                margin="normal"
                                size="small"
                            />
                        </ContainerImgWithAlt>
                    }
                    {
                        (data?.srcSelect && data?.srcSelect?.value == "video from URL") &&
                        <InputData width="70%">
                            <SelectInput
                                data={videoOpt}
                                value={data?.videoType?.value}
                                fieldlabel="value"
                                fieldvalue="value"
                                label={intl.formatMessage({ id: "select video source" })}
                                name="videoType"
                                onChange={handleChangeAutocomplete}
                                width="100%"
                                margin="normal"
                                size="small"
                                required
                            />
                        </InputData>
                    }
                    {
                        (data?.srcSelect && data?.srcSelect?.value == "video from URL" && data?.videoType?.value) &&
                        <InputData width="70%">
                            <StyledTextfield
                                id="srcVideo"
                                name="srcVideo"
                                onChange={onChange}
                                value={data?.srcVideo}
                                label={intl.formatMessage({ id: "link" })}
                                variant="outlined"
                                width="100%%"
                                margin="normal"
                                size="small"
                                required
                            />
                        </InputData>

                    }
                </ContainerImgWithAlt>
                <InputGroup>
                    <StyledTextfield
                        id="action"
                        name="action"
                        onChange={onChange}
                        value={data?.action}
                        label={intl.formatMessage({ id: "text button" })}
                        variant="outlined"
                        width="50%"
                        margin="normal"
                        size="small"
                    />
                    <StyledTextfield
                        id="link"
                        name="link"
                        onChange={onChange}
                        value={data?.link}
                        label={intl.formatMessage({ id: "link" })}
                        variant="outlined"
                        width="50%"
                        margin="normal"
                        size="small"
                    />
                </InputGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            name="invert"
                            checked={data?.invert}
                            onChange={handleChangeCheck}
                            inputProps={{ "aria-label": "controlled invert" }}
                        />
                    }
                    label={intl.formatMessage({ id: "invert" })}
                />
            </Root>
        )
    );
};

export default Timeline;
