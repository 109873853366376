import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { I18nProvider, LOCALES } from "./providers/i18n";
import { ThemeProvider, THEMES } from "./providers/theme";
import AuthProvider from './providers/authentication';
import GlobalStyle from "./globalStyles";
import Protected from './providers/authentication/protected';
// LAYOUTS
import AdminLayout from "./components/layouts/AdminLayout";
// PAGES
import NotFoundPage from './pages/NotFoundPage';
/* import HomePage from './pages/HomePage'; */
import AdminPage from "./pages/AdminPage";
import Login from "./pages/Login";
import UsersPage from './pages/UsersPage';
// COMPONENTS
import MessageBar from "./components/messageBar";
import LodgingsPage from './pages/lodgingsPage';
import NewLodging from './components/lodging/newLodging';
import PostsPage from './pages/postsPage';
import NewPost from './components/post/newPost';
import SectionsPage from './pages/SectionsPage';
import PagesPage from './pages/PagesPage';
import NewPage from './components/page/newPage';
import ConfigSection from './components/section/configSection';
import ProjectsPage from './pages/projectsPage';
import NewProject from './components/projects/newProject';
import ConfigPage from './pages/ConfigPage';

function App() {

  return (
    <React.Fragment>
      <GlobalStyle/>
      <ThemeProvider theme={THEMES.default}>
        <I18nProvider
          locale={LOCALES.SPANISH}
        >
          <AuthProvider>
            {/* <MessageBar /> */}
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              {/* <Route path="/" element={<WebLayout />}>
                <Route index element={<HomePage/>}/>

              </Route> */}
              <Route path="/login" element={<Login />}/>
              <Route exact path="/admin" element={<Protected><AdminLayout /></Protected>}>
                <Route path="/admin/dashboard" element={<AdminPage/>}/>
                <Route path="/admin/configuration" element={<ConfigPage/>}/>
                <Route path="/admin/lodgings" element={<LodgingsPage/>}/>
                <Route path="/admin/newlodging" element={<NewLodging/>}/>
                <Route path="/admin/lodgings/:id" element={<NewLodging/>}/>
                <Route path="/admin/projects" element={<ProjectsPage/>}/>
                <Route path="/admin/newproject" element={<NewProject/>}/>
                <Route path="/admin/projects/:id" element={<NewProject/>}/>
                <Route path="/admin/posts" element={<PostsPage/>}/>
                <Route path="/admin/newpost" element={<NewPost/>}/>
                <Route path="/admin/posts/:id" element={<NewPost/>}/>
                <Route path="/admin/pages" element={<PagesPage />}/>
                <Route path="/admin/newpage" element={<NewPage/>}/>
                <Route path="/admin/pages/:id" element={<NewPage/>}/>
                <Route path="/admin/sections" element={<SectionsPage />}/>
                <Route path="/admin/sections/:id" element={<ConfigSection/>}/>
                <Route path="/admin/users" element={<UsersPage/>}/>
              </Route>
              <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
          </AuthProvider>
        </I18nProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
