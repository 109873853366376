import styled from "styled-components";
import RowDetails from "./RowDetails";
import translate from "../../../providers/i18n/translate";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  background: aliceblue;
  margin: 10px 0;
`;

const THead = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.fieldsNumber ? `repeat(${props.fieldsNumber}, 1fr) 50px` : "auto"};
  gap: 5px;
  min-height: 25px;
  width: 100%;
  height: auto;
`;

const HeadTitle = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: black;
  color: white;
  font-weight: 600;
`;

const DeleteHeadTitle = styled.button`
  background: red;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 2px;
  cursor: pointer;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const AddHeadTitle = styled.button`
  height: 100%;
  width: auto;
  background: #a7a7a7;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  margin: 5px 0;
`;

const DeleteSection = styled.button`
  align-self: flex-end;
  height: 100%;
  width: auto;
  background: red;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  margin: 5px 0;
  
`;

const TBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 5px;
`;

const AddRow = styled.button`
  height: auto;
  width: auto;
  background: #a7a7a7;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  margin: 5px 0;
`;

const FloorSection = ({
  id,
  data,
  sectionindex,
  addHeadTitle,
  deleteSection,
  deleteHeadTitle,
  addRow,
  deleteRow,
  changeHeadTitle,
  changeCell
}) => {
  const intl = useIntl();

  const onNew = () => {
    addHeadTitle(sectionindex);
  }

  const onDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("¿Desea eliminar esta sección?")) {
      deleteSection(sectionindex);
    }
  };

  const onDeleteHeadTitle = (e) => {
    const { column } = e.target.dataset;
    // eslint-disable-next-line no-restricted-globals
    if (confirm("¿Desea eliminar esta columna?")) {
      deleteHeadTitle(sectionindex, column);
    }
  };

  const onNewRow = () => {
    addRow(sectionindex);
  }

  const onDeleteRow = (rowindex) => {
    deleteRow(sectionindex, rowindex);
  }

  const onChangeHeadTitle = (e) => {
    const {value} = e.target;
    const { column } = e.target.dataset;
    changeHeadTitle(sectionindex, column, value);
  };

  const onChangeCell = (rowindex, column, value) => {
    changeCell(sectionindex, rowindex, column, value);
  }

  return (
    <Root>
      <DeleteSection onClick={onDelete}>
        {translate("delete section")}
      </DeleteSection>
      <AddHeadTitle onClick={onNew}>{translate("add head field")}</AddHeadTitle>
      {data && data.thead ? (
        <THead fieldsNumber={data.thead.length}>
          {data.thead.map((headtitle, index) => (
            <HeadTitle
              id={`floor_${id}_headtitle_${index}`}
              key={`floor_section_${id}_headtitle_${index}`}
              data-column={index}
            >
              <input data-column={index} value={headtitle} onChange={onChangeHeadTitle} />
              <DeleteHeadTitle data-column={index} onClick={onDeleteHeadTitle}>
                X
              </DeleteHeadTitle>
            </HeadTitle>
          ))}
        </THead>
      ) : null}

      <TBody>
        {data && data?.tbody
          ? data.tbody.map((row, index) => (
              <RowDetails
                id={`floor_${id}_floor_row_${index}`}
                key={`floor_${id}_floor_row_${index}`}
                data={row}
                rowindex={index}
                onDeleteRow={onDeleteRow}
                changeCell={onChangeCell}
              />
            ))
          : null}
        <AddRow onClick={onNewRow}>{translate("add row")}</AddRow>
      </TBody>
    </Root>
  );
};

export default FloorSection;
