import styled from "styled-components";
import SelectInput from "../../inputs/SelectInput";
import React from "react";
import { API_FRONTEND } from "../../../config";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import translate from "../../../providers/i18n/translate";

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 350px;
  height: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const Title = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  text-transform: uppercase;
`;

const NewAmenitie = ({ onClose, handleNew, selectedAmenities }) => {
  const [selectedAmenitie, setSelectedAmenitie] = React.useState(null);

  const [text, setText] = React.useState("");

  const intl = useIntl();

  const amenities = [
    {
      image: `${API_FRONTEND}/location_icon.svg`,
      fieldname: "Ubicación",
      icon: "/location_icon.svg",
    },
    {
      image: `${API_FRONTEND}/door_open_icon.svg`,
      fieldname: "Ambientes",
      icon: "/door_open_icon.svg",
    },
    {
      image: `${API_FRONTEND}/dimentions_icon_2.svg`,
      fieldname: "Superficie",
      icon: "/dimentions_icon_2.svg",
    },
    {
      image: `${API_FRONTEND}/price_tag_2.svg`,
      fieldname: "Valor",
      icon: "/price_tag_2.svg",
    },
    {
      image: `${API_FRONTEND}/calendar_icon_2.svg`,
      fieldname: "Disponibilidad",
      icon: "/calendar_icon_2.svg",
    },
  ];

  const handleChangeAutocomplete = (name, value) => {
    setSelectedAmenitie(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setText(value);
  };

  const onNew = (e) => {
    e.preventDefault();
    handleNew({
      image: amenities.find((a) => a.icon === selectedAmenitie.value).image,
      icon: selectedAmenitie.value,
      name: text,
    });
    onClose();
  };

  return (
    <Root>
      <Title>{translate("new amenitie")}</Title>
      <SelectInput
        data={amenities.filter(am => !selectedAmenities.find(sa => sa.icon === am.icon))}
        value={selectedAmenitie}
        fieldlabel="fieldname"
        fieldvalue="icon"
        label="Tipo de amenitie"
        name="type"
        onChange={handleChangeAutocomplete}
        width="100%"
        margin="normal"
        size="small"
        required
      />
      <StyledTextfield
        id="text"
        name="text"
        onChange={handleChange}
        value={text}
        label={intl.formatMessage({ id: "text" })}
        variant="outlined"
        width="100%"
        margin="dense"
        size="small"
        required
      />
      <button onClick={onNew}>CREAR</button>
    </Root>
  );
};

export default NewAmenitie;
