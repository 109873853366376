import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import BasicEditor from "../textEditors/basicEditor";
import SelectInput from "../inputs/SelectInput";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const ContentEditor = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const HeroCarouselContentMedia = ({id, editData, handleMediaData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [errors, setErrors] = React.useState({
    title: {
      value: false,
      text: intl.formatMessage({ id: "title must not be empty" }),
      validate: (value) => value == null || value == "", // returns true if there is an error and false if there is not
    },
    positionVData: {
      value: false,
      text: intl.formatMessage({ id: "vertical text align must not be empty" }),
      validate: (value) => value == null || value == "", // returns true if there is an error and false if there is not
    },
    positionHData: {
      value: false,
      text: intl.formatMessage({
        id: "horizontal text align must not be empty",
      }),
      validate: (value) => value == null || value == "", // returns true if there is an error and false if there is not
    },
  });

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setData({
      ...data,
      text: dataeditor,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const vDataOptions = [
    {
      id: "top",
      name: "top",
    },
    {
      id: "middle",
      name: "middle",
    },
    {
      id: "bottom",
      name: "bottom",
    },
  ];

  const hDataOptions = [
    {
      id: "left",
      name: "left",
    },
    {
      id: "right",
      name: "right",
    },
  ];

  const [data, setData] = React.useState(
    editData
      ? {
          ...editData,
          positionVData: {
            label: vDataOptions.find((vd) => vd.id == editData?.positionVData)
              .name,
            value: vDataOptions.find((vd) => vd.id == editData?.positionVData)
              .id,
          },
          positionHData: {
            label: hDataOptions.find((hd) => hd.id == editData?.positionHData)
              .name,
            value: hDataOptions.find((hd) => hd.id == editData?.positionHData)
              .id,
          },
        }
      : {
          pretitle: "",
          title: "",
          text: "",
          positionVData: {
            label: "bottom",
            value: "bottom",
          },
          positionHData: {
            label: "left",
            value: "left",
          },
          action: "",
          link: "",
        }
  );

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = () => {
    if (validateForm()) {
      handleMediaData(id, {
        ...data,
        positionHData: data?.positionHData?.value,
        positionVData: data?.positionVData?.value
      });
      handleClose();
    }
  };

  React.useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Root>
      <Title>
        {editData ? translate("edit content") : translate("add content")}
      </Title>
      <StyledTextfield
          id="pretitle"
          name="pretitle"
          onChange={onChange}
          value={data?.pretitle}
          label={intl.formatMessage({ id: "pretitle" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
      <InputData width="100%">
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: "title" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          required
        />
        <InputError show={errors.title.value}>{errors.title.text}</InputError>
      </InputData>
      <ContentEditor>
        <BasicEditor
          name="text"
          value={data.text}
          onChange={handleEditor}
          editorLoaded={editorLoaded}
          maxContentHeight="150px"
          minContentHeight="150px"
          placeholder={intl.formatMessage({ id: "insert text here..." })}
        />
      </ContentEditor>
      <StyledTextfield
        id="action"
        name="action"
        onChange={onChange}
        value={data?.action}
        label={intl.formatMessage({ id: "text button" })}
        variant="outlined"
        width="100%"
        margin="normal"
        size="small"
      />
      <StyledTextfield
        id="link"
        name="link"
        onChange={onChange}
        value={data?.link}
        label={intl.formatMessage({ id: "link" })}
        variant="outlined"
        width="100%"
        margin="normal"
        size="small"
      />
      <InputGroup>
        <InputData width="50%">
          <SelectInput
            data={vDataOptions}
            value={data.positionVData}
            fieldlabel="name"
            fieldvalue="id"
            label="vertical text align"
            name="positionVData"
            onChange={handleChangeAutocomplete}
            width="100%"
            margin="normal"
            size="small"
            required
          />
          <InputError show={errors.positionVData.value}>
            {errors.positionVData.text}
          </InputError>
        </InputData>
        <InputData width="50%">
          <SelectInput
            data={hDataOptions}
            value={data.positionHData}
            fieldlabel="name"
            fieldvalue="id"
            label="horizontal text align"
            name="positionHData"
            onChange={handleChangeAutocomplete}
            width="100%"
            margin="normal"
            size="small"
            required
          />
          <InputError show={errors.positionHData.value}>
            {errors.positionHData.text}
          </InputError>
        </InputData>
      </InputGroup>
      <Button
        onClick={submit}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default HeroCarouselContentMedia;
