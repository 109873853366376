import React from "react";
import styled from "styled-components";
import { API_BACKEND } from "../../config";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import Media from "../inputGallery/media";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-width: 350px;
  padding: 20px 0;
  height: 100%;
`;
const Title = styled.div`
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddImage = styled.label`
  position: relative;
  color: ${(props) => props.theme.palette.principal};
  cursor: pointer;
  margin-right: 1rem;
`;

const Images = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.forceColumn ? "column" : "row")};
  gap: ${(props) => (props.forceColumn ? "20px" : "50px")};
  align-items: ${(props) => (props.forceColumn ? "center" : "flex-start")};
  justify-content: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 20px;
`;



const ControlledInputGallery = ({ id, name, title, forceColumn, withContent, contentMediaType, data, setData, notifyEdit }) => {

    const theme = React.useContext(ThemeContext);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const handleDelete = (id) => {
      const newMedias = data.filter((m) => m.id != id);
      setData([...newMedias]);
      notifyEdit(name);
    };

    const compareMedias = (a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    };

    const handleUp = (id) => {
      const newMedias = [];
      data.forEach((element, index) => {
        newMedias.push({
          id: element.id,
          url: element.url,
          alt: element.alt,
          order: index,
          file: element.file,
          data: element.data
        });
      });
      const indexMedia = newMedias.findIndex((m) => m.id === id);
      const ordertmp = newMedias[indexMedia - 1].order;
      newMedias[indexMedia - 1].order = newMedias[indexMedia].order;
      newMedias[indexMedia].order = ordertmp;
      newMedias.sort(compareMedias);
      setData([...newMedias]);
      notifyEdit(name);
    };

    const handleDown = (id) => {
      const newMedias = [];
      data.forEach((element, index) => {
        newMedias.push({
          id: element.id,
          url: element.url,
          alt: element.alt,
          order: index,
          file: element.file,
          data: element.data
        });
      });
      const indexMedia = newMedias.findIndex((m) => m.id === id);
      const ordertmp = newMedias[indexMedia + 1].order;
      newMedias[indexMedia + 1].order = newMedias[indexMedia].order;
      newMedias[indexMedia].order = ordertmp;
      newMedias.sort(compareMedias);
      setData([...newMedias]);
      notifyEdit(name);
    };

    const onChange = (e) => {
      const { files } = e.target;
      if (files.length > 0) {
        const formData = new FormData();
        formData.append("image", files[0]);
        axios
          .post(`${API_BACKEND}/sections/images`, formData, {})
          .then((res) => {
            const newMedias = [...data];
            newMedias.push({
              id: res.data && res.data.id ? res.data.id : uuidv4(),
              url: res.data.url,
              alt: "new image",
              order: data.length > 0 ? data[data.length - 1].order + 1 : 0,
              file: files[0],
              data: null
            });
            setData([...newMedias]);
            notifyEdit(name);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      }
    };

    const handleMediaData = (id, mediaData) => {
      const newMedias = data;
      const updateMedia = newMedias.find((m) => m.id === id);
      updateMedia.data = mediaData;
      setData([...newMedias]);
      notifyEdit(name);
    }


    return (
      <Root>
        <Title>
          {title ?? translate("Gallery")}
          <AddImage htmlFor={`gallery-button-file_${id ? id : ""}`}>{theme.icons.add}</AddImage>
        </Title>
        <Images forceColumn={forceColumn}>
          {data.length > 0 &&
            data.map((m, index) => (
              <Media
                key={`media_${index}`}
                forceColumn={forceColumn}
                data={m}
                handleDelete={handleDelete}
                handleUp={handleUp}
                handleDown={handleDown}
                handleMediaData={handleMediaData}
                first={index === 0}
                last={index === data.length - 1}
                withContent={withContent}
                contentMediaType={withContent ? contentMediaType : null}
              />
            ))}
        </Images>
        <input
          id={`gallery-button-file_${id ? id : ""}`}
          name="photo"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={onChange}
        />
      </Root>
    );
  }

export default ControlledInputGallery;



export const loadImages = async (imagesToLoad) => {
  const galleryImages = [];
  for (let index = 0; index < imagesToLoad.length; index++) {
    const res = await fetch(imagesToLoad[index].url);
    const buf = await res.arrayBuffer();
    const file = new File([buf], imagesToLoad[index].alt, {
      type: "image/webp",
    });
    galleryImages.push({
      ...imagesToLoad[index],
      file: file,
    });
  }
  return [...galleryImages];
};
