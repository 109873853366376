import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import axios from "axios";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import { API_BACKEND } from "../../config";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import ResponsiveInputImage from "../inputs/ResponsiveInputImage";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 800px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const InputsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const InputGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
`

const ImageContainer = styled.div`
  width: 35%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;


const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const TextButtonSliderContentMedia = ({ id, editData, handleMediaData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const [data, setData] = React.useState(
    editData
      ? {
        ...editData
      }
      : {
        quote: "",
        name: "",
        jobTitle: "",
      }
  );

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const onChangeImage = (files) => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("image", files[0]);
            axios
                .post(`${API_BACKEND}/sections/images`, formData, {})
                .then((res) => {
                    setData({
                        ...data,
                        img: res.data.url,
                    });
                })
                .catch((error) => {
                    alertMessage(error.response?.data?.msg, alertConstants.ERROR);
                });
        }
    };

    const onResetImage = () => {
        setData({
            ...data,
            img: null,
        });
    };

  const submit = () => {
    handleMediaData(id, {
      ...data,
      positionHData: data?.positionHData?.value,
      positionVData: data?.positionVData?.value
    });
    handleClose();
  };

  return (
    <Root>
      <Title>
        {editData ? translate("edit content") : translate("add content")}
      </Title>
      <InputsContainer>
        <InputGroup>
          <InputData width="100%">
            <StyledTextfield
            id="quote"
            name="quote"
            onChange={onChange}
            value={data?.quote}
            label={intl.formatMessage({ id: "quote" })}
            variant="outlined"
            width="100%"
            margin="normal"
            size="small"
            multiline
            rows={5}
          />
            </InputData>
          <InputData width="100%">
            <StyledTextfield
            id="name"
            name="name"
            onChange={onChange}
            value={data?.name}
            label={intl.formatMessage({ id: "name" })}
            variant="outlined"
            width="100%"
            margin="normal"
            size="small"
          />
            </InputData>
          <InputData width="100%">
            <StyledTextfield
            id="jobTitle"
            name="jobTitle"
            onChange={onChange}
            value={data?.jobTitle}
            label={intl.formatMessage({ id: "job title" })}
            variant="outlined"
            width="100%"
            margin="normal"
            size="small"
          />
            </InputData>
        </InputGroup>
      <ImageContainer>
          <ResponsiveInputImage
              file={data.img}
              onChange={onChangeImage}
              onReset={onResetImage}
          />
      </ImageContainer>
      </InputsContainer>
      <Button
        onClick={submit}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default TextButtonSliderContentMedia;
