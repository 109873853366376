import React from "react";
import styled from "styled-components";
import { API_FRONTEND } from "../../../config";
import Amenitie from "./amenitie";
import { Modal } from "@mui/material";
import NewAmenitie from "./newAmenitie";
import translate from "../../../providers/i18n/translate";
import { ThemeContext } from "../../../providers/theme";

const Root = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const Title = styled.div`
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContainerAmenities = styled.div`
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

const AddImage = styled.button`
  position: relative;
  color: ${(props) => props.theme.palette.principal};
  cursor: pointer;
  margin-right: 1rem;
  border: none;
  background: none;
`;

const Amenities = ({amenities, setAmenities}) => {

  const theme = React.useContext(ThemeContext);
  const [openAmenitie, setOpenAmenitie] = React.useState(false);

  const deleteAmenitie = (e) => {
    e.preventDefault();
    const { name } = e.currentTarget.dataset;
    const newamenities = amenities.filter((a) => a.name != name);
    setAmenities([...newamenities]);
  };

  const addAmenitie = (newAmenitie) => {
    const newAmenities = [...amenities];
    newAmenities.push(newAmenitie);
    setAmenities([...newAmenities]);
  };

  const onOpenAmenitie = (e) => {
    e.preventDefault();
    setOpenAmenitie(true);
  };

  const onCloseAmenitie = () => {
    setOpenAmenitie(false);
  };

  React.useEffect(() => {}, [amenities]);
  return (
    <Root>
      <Title>
        {translate("Amenities")}
        <AddImage onClick={onOpenAmenitie}>
          {theme.icons.add}
        </AddImage>
      </Title>
      <ContainerAmenities>
        {amenities &&
          amenities.map((am, index) => (
            <Amenitie
              key={`amenitie_${index}`}
              icon={am.image}
              name={am.name}
              handleDelete={deleteAmenitie}
            />
          ))}
      </ContainerAmenities>
      <Modal open={openAmenitie} onClose={onCloseAmenitie}>
        <NewAmenitie selectedAmenities={amenities} onClose={onCloseAmenitie} handleNew={addAmenitie} />
      </Modal>
    </Root>
  );
};

export default Amenities;
