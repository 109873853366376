import React from "react";
import styled from "styled-components";
import { API_FRONTEND } from "../../../config";
import Amenitie from "./amenitie";

const Root = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

const Amenities = () => {
  const [amenities, setAmenities] = React.useState([
    {
      icon: `${API_FRONTEND}/sillon_icon.svg`,
      name: "amenitie 1",
    },
    {
      icon: `${API_FRONTEND}/sillon_icon.svg`,
      name: "amenitie 2",
    },
    {
      icon: `${API_FRONTEND}/sillon_icon.svg`,
      name: "amenitie 3",
    },
  ]);

  const deleteAmenitie = (e) => {
    e.preventDefault();
    const { name } = e.currentTarget.dataset;
    const newamenities = amenities.filter((a) => a.name != name);
    setAmenities([...newamenities]);
  };

  React.useEffect(() => {}, [amenities]);

  return (
    <Root>
      {amenities &&
        amenities.map((am, index) => (
          <Amenitie
            key={`amenitie_${index}`}
            icon={am.icon}
            name={am.name}
            handleDelete={deleteAmenitie}
          />
        ))}
    </Root>
  );
};

export default Amenities;
