import React from "react";
import styled from "styled-components";
import { Table } from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from "axios";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useIntl } from "react-intl";
import { API_BACKEND } from "../config";
import AddIcon from "@mui/icons-material/Add";
import { Modal, Tooltip } from "@mui/material";
import CreateSection from "../components/section/createSection";
import hasScope from '../providers/authentication/hasScope';
import { useAuth } from "../providers/authentication";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : "#000")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NewIcon = styled.div`
  position: sticky;
  right: 20px;
  top: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.bg ? props.bg : "7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1);
  }
  z-index: 99;
  @media (min-width: 1024px) {
    right: 50px;
    bottom: 50px;
  }
`;

const BackButton = styled.button`
  background: ${(props) => props.theme.palette.base};
  border: none;
  cursor: pointer;
`;

export default function SectionsPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const navigate = useNavigate();
  const { token } = useAuth();


  const intl = useIntl();

  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    tableRef.current.clean();
    setOpen(false);
  };

  const handleEdit = (data) => {
    setEditData(data);
    setOpen(true);
  };

  const columnsTable = [
    { title: "ID", field: "id", width: "10%", order: false, hidden: false },
    {
      title: translate("order"),
      field: "order",
      order: false,
      width: "10%",
      hidden: false,
    },
    {
      title: translate("name"),
      field: "name",
      order: false,
      width: "30%",
      hidden: false,
    },
    {
      title: translate("type"),
      field: "type",
      order: false,
      width: "25%",
      hidden: false,
    },
    {
      title: translate("slug"),
      field: "slug",
      order: false,
      width: "25%",
      hidden: false,
    },
    {
      title: translate("actions"),
      actions: true,
      width: "auto",
      hidden: false,
    },
  ];

  const handleNewItem = () => {
    setEditData(null);
    handleOpen();
  };

  const handleSettingItem = (data) => {
    navigate(`/admin/sections/${data.id}`);
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_BACKEND}/sections/${data.id}`)
        .then((result) => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const actionsTable = {
    view: {
      icon: theme.icons.settings,
      tooltip: "Configurar",
      onClick: handleSettingItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
    edit: {
      icon: theme.icons.edit,
      tooltip: "Editar",
      onClick: handleEdit,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
    delete: {
      icon: theme.icons.delete,
      tooltip: "Eliminar",
      onClick: handleDeleteItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
  };

  return (
    <Root>
      <Header>
        <Title color={theme.palette.principal}>{translate("Sections")}</Title>
        <Tooltip
          title={intl.formatMessage({ id: "back to pages" })}
          placement="bottom"
        >
          <BackButton onClick={handleBack}>{theme.icons.back}</BackButton>
        </Tooltip>
      </Header>
      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          disablePlain
          data={(query) =>
            new Promise((resolve, reject) => {
              const url = page
                ? `${API_BACKEND}/sections/page/${page}`
                : `${API_BACKEND}/sections`;
              axios
                .get(url)
                .then((result) => {
                  resolve({
                    rows: result.data,
                    total: result.data.length,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        ></Table>
        {hasScope(token, 'newSection') &&
          <NewIcon
          color={theme.palette.base}
          bg={theme.palette.accent}
          onClick={handleNewItem}
          >
          <AddIcon />
        </NewIcon>
        }
      </Content>
      <Modal open={open} onClose={handleClose}>
        <CreateSection
          editData={editData}
          handleClose={handleClose}
          page={page}
        />
      </Modal>
    </Root>
  );
}
