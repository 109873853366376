import React from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import { useIntl } from "react-intl";

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const MapProject = ({ data, setData }) => {
  const intl = useIntl();
  
  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Root>
      <StyledTextfield
          id="src"
          name="src"
          onChange={onChange}
          value={data?.src}
          label={intl.formatMessage({ id: "src map" })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          required
        />
    </Root>
  );
};

export default MapProject;
