import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { alertConstants } from "../../../store/reducers/messageBar/alertConstants";
import axios from "axios";
import { API_BACKEND } from "../../../config";
import InputImage from "../../inputs/inputImage";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import BasicEditor from "../../textEditors/basicEditor";
import EmailInput from "../../inputs/EmailInput";

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 1/6;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const ContentEditor = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  grid-column: 1/2;
`;

const FormProject = ({ data, setData }) => {
    const intl = useIntl();
    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const dispatch = useDispatch();

    const { alertMessage } = bindActionCreators(actionCreators, dispatch);

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleFromChange = (value) => {
        setData(() => ({
            ...data,
            from: value,
        }));
    };

    const handleToChange = (value) => {
        setData(() => ({
            ...data,
            to: value,
        }));
    };

    const onChangeImage = (files) => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("image", files[0]);
            axios
                .post(`${API_BACKEND}/sections/images`, formData, {})
                .then((res) => {
                    setData({
                        ...data,
                        img: res.data.url,
                    });
                })
                .catch((error) => {
                    alertMessage(error.response?.data?.msg, alertConstants.ERROR);
                });
        }
    };

    const onResetImage = () => {
        setData({
            ...data,
            img: null,
        });
    };

    const handleEditorEmailHeader = (e, dataeditor) => {
        setData({
            ...data,
            emailHeader: dataeditor
        })
    }

    const handleEditorEmailSignature = (e, dataeditor) => {
        setData({
            ...data,
            emailSignature: dataeditor
        })
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, []);

    return (
        data && (
            <Root>
                <InputData>
                    <StyledTextfield
                        id="title"
                        name="title"
                        onChange={onChange}
                        value={data?.title}
                        label={intl.formatMessage({ id: "title" })}
                        variant="outlined"
                        width="100%"
                        margin="normal"
                        size="small"
                        required
                    />
                </InputData>
                <InputData>
                    <StyledTextfield
                        id="subtitle"
                        name="subtitle"
                        onChange={onChange}
                        value={data?.subtitle}
                        label={intl.formatMessage({ id: "subtitle" })}
                        variant="outlined"
                        width="100%"
                        margin="normal"
                        size="small"
                        required
                    />
                </InputData>
                <InputData>
                    <EmailInput
                        id="from"
                        name="from"
                        placeholder="Escribir email y presionar Enter"
                        label={intl.formatMessage({ id: "from" })}
                        editData={data?.from}
                        onChange={handleFromChange}
                    />
                </InputData>
                <InputData>
                    <EmailInput
                        id="to"
                        name="to"
                        placeholder="Escribir email y presionar Enter"
                        label={intl.formatMessage({ id: "to" })}
                        editData={data?.to}
                        onChange={handleToChange}
                    />
                </InputData>
                <InputData>
                    <StyledTextfield
                        id="subject"
                        name="subject"
                        onChange={onChange}
                        placeholder={intl.formatMessage({ id: "subject" })}
                        value={data?.subject}
                        label={intl.formatMessage({ id: "subject" })}
                        variant="outlined"
                        width="100%"
                        margin="normal"
                        size="small"
                        required
                    />
                </InputData>
                <ContentEditor>
                    <BasicEditor
                        name="emailHeader"
                        value={data?.emailHeader}
                        onChange={handleEditorEmailHeader}
                        editorLoaded={editorLoaded}
                        maxContentHeight="200px"
                        minContentHeight="200px"
                        placeholder={intl.formatMessage({ id: "insert email header here..." })}
                    />
                </ContentEditor>
                <ContentEditor>
                    <BasicEditor
                        name="emailSignature"
                        value={data?.emailSignature}
                        onChange={handleEditorEmailSignature}
                        editorLoaded={editorLoaded}
                        maxContentHeight="200px"
                        minContentHeight="200px"
                        placeholder={intl.formatMessage({ id: "insert email signature here..." })}
                    />
                </ContentEditor>
                <ContainerImgWithAlt>
                    <ImageContainer>
                        <InputImage
                            id="formProject"
                            file={data.img}
                            onChange={onChangeImage}
                            onReset={onResetImage}
                        />
                    </ImageContainer>
                    <StyledTextfield
                        id="alt"
                        name="alt"
                        onChange={onChange}
                        value={data?.alt}
                        label={intl.formatMessage({ id: "alternative text" })}
                        variant="outlined"
                        width="70%"
                        margin="normal"
                        size="small"
                    />
                    <InputData>
                        <StyledTextfield
                            id="textButton"
                            name="textButton"
                            onChange={onChange}
                            value={data?.textButton}
                            label={intl.formatMessage({ id: "textButton" })}
                            variant="outlined"
                            width="70%"
                            margin="normal"
                            size="small"
                            required
                        />
                    </InputData>
                </ContainerImgWithAlt>
            </Root>
        )
    );
};

export default FormProject;
