import { TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { API_BACKEND } from "../../config";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { alertConstants } from "../../store/reducers/messageBar/alertConstants";
import SelectInput from "../inputs/SelectInput";
import { sectionTypeOptions } from "./sectionTypes";
import { useAuth } from "../../providers/authentication";
import { decodeToken } from "../../hooks/jwt";

/* Styled components */
const Root = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const CreateSection = ({ editData, page, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const [pageList, setPageList] = React.useState(null);

  const [data, setData] = React.useState(editData ? 
    {
    ...editData,
    page: null,
    }
    :
    {
      name: "",
    type: null,
    order: "",
    data: null,
    page: null,
    }
  );

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("order", data.order);
    formData.append("page", data.page?.value);
    formData.append("user", userData?.id);
    if(editData){
      formData.append("type", editData.type);
      axios
      .put(`${API_BACKEND}/sections/${editData.id}`, formData, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "section edited" }),
          alertConstants.INFO
        );
        handleClose();
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
    } else {
      formData.append("type", data.type?.value);
      axios
      .post(`${API_BACKEND}/sections`, formData, {})
      .then((res) => {
        alertMessage(
          intl.formatMessage({ id: "section created" }),
          alertConstants.INFO
        );
        handleClose();
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
    }
  };

  const getPagesList = () => {
    axios
      .get(`${API_BACKEND}/pages`)
      .then((res) => {
        setPageList(res.data);
        if (page) {
          const selectedPage = res.data?.find((p) => p.id == page);
          setData({
            ...data,
            page: {
              label: selectedPage.name,
              value: selectedPage.id
            },
          });
        }
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  React.useEffect(() => {
    if (!pageList) {
      getPagesList();
    }
  }, []);

  return (
    <Root onSubmit={handleAction}>
      <Title>{editData ? translate("edit section") : translate("new section")}</Title>
      {pageList && (
        <SelectInput
          data={pageList}
          value={data.page}
          fieldlabel="name"
          fieldvalue="id"
          label="page"
          name="page"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="normal"
          size="small"
          required
        />
      )}
      <TextField
        id="name"
        name="name"
        onChange={handleChange}
        value={data.name}
        label={intl.formatMessage({ id: "name" })}
        variant="outlined"
        margin="normal"
        size="small"
        required
        fullWidth
      />
      <TextField
        id="order"
        name="order"
        type="number"
        onChange={handleChange}
        value={data.order}
        label={intl.formatMessage({ id: "order" })}
        variant="outlined"
        margin="normal"
        size="small"
        required
        fullWidth
      />
      {
        !editData &&
        <SelectInput
        data={sectionTypeOptions}
        value={data.type}
        fieldlabel="name"
        fieldvalue="id"
        label="section type"
        name="type"
        onChange={handleChangeAutocomplete}
        margin="normal"
        size="small"
        required
        width="100%"
      />
      }
      <Button bg={theme.palette.accent} color={theme.palette.base}>
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default CreateSection;
