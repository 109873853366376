import React from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../providers/theme";

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`

const Icon = styled.img`
    width: 50px;
    height: 50px;
`

const Name = styled.span`

`

const Delete = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    svg {
        font-size: 1rem;
    }
    background: transparent;
    border: none;
    cursor: pointer;
`

const Amenitie = ({icon, name, handleDelete}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root data-name={name} onClick={handleDelete}>
            <Icon src={icon} alt={name} />
            <Name>{name}</Name>
            <Delete>{theme.icons.close}</Delete>
        </Root>
    );
}

export default Amenitie;