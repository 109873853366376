import React from "react";
import styled from "styled-components";
import { ThemeContext } from '../../../providers/theme';
import ItemList from "./ItemList";
import translate from '../../../providers/i18n/translate';
import { Tooltip } from "@mui/material";
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/authentication";
import { MOLE_URL } from "../../../config";
import Private from "../../../providers/authentication/private";
/* import Notifications from "../../notifications"; */

const Root = styled.aside`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.openNav ? "100%" : "50px"};
    display: grid;
    grid-template-rows: 50px 1fr 50px 50px;
    overflow-y: hidden;
    background-color: ${props => props.theme.palette.principal};
    transition: height 500ms ease-in-out;
    z-index: 9999;
    @media (min-width: 1024px) {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: ${(props) => props.openNav ? props.openWidth : props.closeWidth};
        height: 100%;
        top: inherit;
        left: inherit;
        z-index: inherit;
        overflow-x: hidden;
        overflow-y: inherit;
        grid-template-rows: inherit;
        transition: width 500ms ease-in-out;
    }
`

const Switch = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        transition: all 200ms ease-in-out;
        color: ${(props) => props.color ? props.color : "#FFF"};
    }
    @media (min-width: 1024px) {
        svg:hover {
            transform: scale(1.1);
        }
    }
`

const MenuList = styled.ul`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    @media (min-width: 1024px) {
        display: flex;
        padding: 0;
    }
`

const Icon = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        transition: all 200ms ease-in-out;
        color: ${props => props.theme.palette.base};
    }
    span {
        color: ${props => props.theme.palette.base};
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        opacity: ${(props) => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
    }
    @media (min-width: 1024px) {
        svg:hover {
            transform: scale(1.1);
        }
    }
`

const Separator = styled.div`
    width: 90%;
    height: 1px;
    background-color: ${props => props.theme.palette.accent};
`

const Mole = styled.a`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    svg {
        transform: rotate(90deg);
        transition: all 200ms ease-in-out;
        color: ${props => props.theme.palette.base};
    }
    span {
        color: ${props => props.theme.palette.base};
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        opacity: ${(props) => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
        font-size: 0.6rem;
    }
    @media (min-width: 1024px) {
        svg:hover {
            transform: scale(1.1) rotate(270deg);
            color: #00ffbb;
        }
    }
`


const AdminNav = ({ openNav, onSwitchNav, closeNav }) => {

    const intl = useIntl();

    let navigate = useNavigate();

    const { onLogout } = useAuth();

    const OPEN_NAV_WIDTH = "200px";
    const CLOSE_NAV_WIDTH = "50px";

    const logout = () => {
        onLogout(() => navigate("/"));
    }

    const theme = React.useContext(ThemeContext);

    const menuList = [
        {
            name: translate("dashboard"),
            link: "/admin/dashboard",
            icon: theme.icons.gridView,
            scope: "dashboard",
            private: true
        },
        {
            name: translate("lodgings"),
            link: "/admin/lodgings",
            icon: theme.icons.lodgings,
            scope: "lodgings",
            private: true
        },
        {
            name: translate("projects"),
            link: "/admin/projects",
            icon: theme.icons.lodgings,
            scope: "projects",
            private: true
        },
        {
            name: translate("posts"),
            link: "/admin/posts",
            icon: theme.icons.pubs,
            scope: "posts",
            private: true
        },
        {
            name: translate("pages"),
            link: "/admin/pages",
            icon: theme.icons.pages,
            scope: "pages",
            private: true
        },
        {
            name: translate("users"),
            link: "/admin/users",
            icon: theme.icons.person,
            scope: "users",
            private: true
        },
        {
            name: translate("configuration"),
            link: "/admin/configuration",
            icon: theme.icons.settings,
            scope: "configuration",
            private: true
        },
        {
            separator: true
        }
    ]

    return (
        <Root openNav={openNav} openWidth={OPEN_NAV_WIDTH} closeWidth={CLOSE_NAV_WIDTH} >
            <Switch openNav={openNav}>
                {
                    openNav ?
                        <Icon onClick={onSwitchNav}>{theme.icons.arrowBack}</Icon>
                        :
                        <Icon onClick={onSwitchNav}>{theme.icons.menu}</Icon>
                }
            </Switch>
            <MenuList openNav={false}>
                {
                    menuList && menuList.map((item, index) =>
                        item.separator ?
                            <Separator key={`separator_${index}_${item.name}`} />
                            :
                            item.private ?
                                <Private scope={item.scope} nullReturn key={`item_${index}_${item.name}`}>
                                    <ItemList item={item} closeNav={closeNav} showname={openNav ? 1 : 0} />
                                </Private>
                                :
                                <ItemList key={`item_${index}_${item.name}`} item={item} closeNav={closeNav} showname={openNav ? 1 : 0} />
                    )
                }
            </MenuList>
            {/* <Notifications openNav={openNav} closeNav={closeNav} /> */}
            <Tooltip title={intl.formatMessage({ id: 'exit' })} placement={openNav ? "left" : "right"}>
                <Icon onClick={logout} showname={openNav ? 1 : 0}>
                    {theme.icons.logout}
                    <span>{translate("exit")}</span>
                </Icon>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'mole' })} placement={openNav ? "top" : "right"}>
                <Mole href={MOLE_URL} target="_blank" showname={openNav ? 1 : 0}>
                    {theme.icons.hexagon}
                    <span>Mole</span>
                </Mole>
            </Tooltip>
        </Root>
    );
}

export default AdminNav;
